












import { Component, Prop, Vue } from 'vue-property-decorator';
import { MutationMethod } from 'vuex';

@Component({})
export default class SnackBar extends Vue {
  @Prop() text!: String;
  @Prop({ default: false }) isShowSnackBar!: Boolean;
  @Prop() timeout!: Number;
  @Prop() color?: String;
  @Prop({ default: true }) dark!: Boolean;
  @Prop() setSnackBarData!: MutationMethod;

  get snackBar() {
    return this.isShowSnackBar;
  }
  set snackBar(val) {
    this.setSnackBarData({
      text: '',
      show: val,
      timeout: 0,
      color: '',
      dark: true,
    });
  }
}
